import baseApi from './base_api.js'

//  查询评论
export function allMessage(options) {
  return baseApi.post('/allMessage', {...options})
}

// 发表评论
export function addMessage(options) {
  return baseApi.post('/addMessage', {...options})
}

// 回复评论
export function addReply(options) {
  return baseApi.post('/addReply', {...options})
}