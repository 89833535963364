<template>
  <div class="editor">
    <menu-bar class="editor__header" :editor="editor" @showEmoji="showEmoji" />
    <editor-content class="editor__content" :editor="editor"/>
    <div v-show="showEmojiBox" class="emoji_box">
      <template v-for="(item, index) in emoji">
        <div class="emoji_item" v-if="item.group == 'Smileys & Emotion' || item.subgroup.indexOf('hand-fingers-') != -1" :Key="index" @click="clickEmoji(item)">
          {{item.char}}
        </div>
      </template>
    </div>
    <div class="publish_btn" @click="publish">
      <i class="iconfont iconfasong"></i>
    </div>
    <pop
      ref="publishTitle"
      :title="finalMes"
      cancel-text="取消"
      :show-sure=false
      @cancel="cancel" />
  </div>
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import StarterKit from '@tiptap/starter-kit'
  import menuBar from './component/menuBar'
  import pop from '@/component/pop/pop.vue'
  import TaskList from '@tiptap/extension-task-list'
  import TaskItem from '@tiptap/extension-task-item'
  import Placeholder from '@tiptap/extension-placeholder'
  import Highlight from '@tiptap/extension-highlight'
  import emoji from '@/assets/emoji.json'
  import { addMessage, addReply } from '@/vue_api/message.js'
  export default{
    name: 'editor',
    components: {
      EditorContent,
      menuBar,
      pop
    },
    data() {
      return {
        editor: null,
        emoji: emoji,
        showEmojiBox: false,
        finalMes: ''
      }
    },
    props: {
      actionType: {
        type: String,
        default: 'msg'
      },
      item: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    created() {
      this.editor = new Editor({
        extensions: [
          StarterKit.configure({
            history: false,
          }),
          Highlight,
          TaskList,
          TaskItem,
          Placeholder.configure({
            placeholder: '说点什么吧~'
          })
        ],
      })
      // console.log('editorIsActive-----', this.editor, this.userName)
    },
    mounted() {
      this.$bus.$on('closeEmoji', (val) => {
        this.showEmojiBox = val
      })
      this.$bus.$on('editFocus', (val) => {
        val && this.editor.commands.focus()
      })
      console.log('editormounted------', this.emoji[2].char, this.actionType)
    },
    methods: {
      // 发表
      publish() {
        console.log('点击了发表', this.actionType, this.item, JSON.stringify(this.editor.getHTML()), this.editor.getJSON())
        if(!this.actionType) {
          this.finalMes = '无法判断是回复还是发表，请重试~'
          this.$refs.publishTitle.open()
          return
        }
        if(this.actionType == 'reply' && JSON.stringify(this.item) == '{}') {
          this.finalMes = '无法知道回复的是哪条评论，请重试~'
          this.$refs.publishTitle.open()
          return
        }
        if(JSON.stringify(this.editor.getHTML()) == '"<p></p>"') {
          this.finalMes = '无内容可提交哦~'
          this.$refs.publishTitle.open()
          return
        }
        if(!(this.editor.getJSON().content[0].content[0].text && this.editor.getJSON().content[0].content[0].text.trim())) {
          this.finalMes = '写点什么东西吧~'
          this.$refs.publishTitle.open()
          return
        }
        if(!this.userName) {
          this.finalMes = '留言需要先登录哦~'
          this.$refs.publishTitle.open()
          return
        }
        const params = this.actionType == 'msg' ? {
          content: this.editor.getHTML(),
          editor: this.userName
        } : {
          msgId: this.item.id,
          replier: this.userName,
          replyContent: this.editor.getHTML()
        }
        const apiName = this.actionType == 'msg' ? addMessage : addReply
        apiName({...params}).then(res => {
          console.log('----', res)
          this.$emit('publish', 1)
          this.editor.commands.clearContent()
        })
      },
      cancel() {
        this.$refs.publishTitle.close()
      },
      clickEmoji(item) {
        // console.log(item)
        this.editor.commands.insertContent(item.char)
        this.showEmojiBox = false
      },
      showEmoji(val) {
        this.editor.commands.focus()
        console.log(val, this.editor.getHTML());
        this.showEmojiBox = !this.showEmojiBox
      }
    },
    beforeDestroy() {
      this.editor.destroy()
    }
  }
</script>

<style lang="scss" scopted>
  @import './index.scss'
</style>

<style lang="scss">
/* Basic editor styles */
@import './proseMirror.scss'
</style>