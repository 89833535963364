<template>
  <div>
    <template v-for="(item, index) in items">
      <!-- <div class="divider" v-if="item.type === 'divider'" :key="index" /> -->
      <menu-item :key="index" v-bind="item" />
    </template>
  </div>
</template>

<script>
import MenuItem from '../menuItem'

export default {
  components: {
    MenuItem,
  },

  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          icon: 'icon-emoji',
          title: '表情',
          action: () => this.$emit('showEmoji', 1)
        },
        {
          icon: 'iconjiacu',
          title: '加粗',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'iconxieti',
          title: '斜体',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'iconstrikethrough',
          title: '中线',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'iconhighlight',
          title: '高亮',
          action: () => this.editor.chain().focus().toggleHighlight().run(),
          isActive: () => this.editor.isActive('highlight'),
        },
        // {
        //   type: 'divider',
        // },
        {
          icon: 'iconbulletlist',
          title: '无序列表',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'iconorderedlist',
          title: '有序列表',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'iconTask',
          title: '任务列表',
          action: () => this.editor.chain().focus().toggleTaskList().run(),
          isActive: () => this.editor.isActive('taskList'),
        },
        {
          icon: 'icontoolbar-clear',
          title: '清除格式',
          action: () => this.editor.chain()
            .focus()
            .clearNodes()
            .unsetAllMarks()
            .run(),
        }
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>